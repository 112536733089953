import aaronAudio from '@/assets/audio/voices/aaron.mp3';
import allisonAudio from '@/assets/audio/voices/allison.mp3';
import amyAudio from '@/assets/audio/voices/amy.mp3';
import beatrixAudio from '@/assets/audio/voices/beatrix.mp3';
import claireAudio from '@/assets/audio/voices/claire.mp3';
import danAudio from '@/assets/audio/voices/dan.mp3';
import elizabethAudio from '@/assets/audio/voices/elizabeth.mp3';
import harveyAudio from '@/assets/audio/voices/harvey.mp3';
import hughAudio from '@/assets/audio/voices/hugh.mp3';
import justinAudio from '@/assets/audio/voices/justin.mp3';
import leslieAudio from '@/assets/audio/voices/leslie.mp3';
import livAudio from '@/assets/audio/voices/liv.mp3';
import millieAudio from '@/assets/audio/voices/millie.mp3';
import monicaAudio from '@/assets/audio/voices/monica.mp3';
import rupertAudio from '@/assets/audio/voices/rupert.mp3';
import scarlettAudio from '@/assets/audio/voices/scarlett.mp3';
import tylerAudio from '@/assets/audio/voices/tyler.mp3';
import valerieAudio from '@/assets/audio/voices/valerie.mp3';
import willAudio from '@/assets/audio/voices/will.mp3';
import zacharyAudio from '@/assets/audio/voices/zachary.mp3';
import aaronImg from '@/assets/imgs/voices/voice-Aaron.jpeg';
import allisonImg from '@/assets/imgs/voices/voice-Allison.jpeg';
import amyImg from '@/assets/imgs/voices/voice-Amy.jpeg';
import beatrixImg from '@/assets/imgs/voices/voice-Beatrix.jpeg';
import claireImg from '@/assets/imgs/voices/voice-Claire.jpeg';
import danImg from '@/assets/imgs/voices/voice-Dan.jpeg';
import elizabethImg from '@/assets/imgs/voices/voice-Elizabeth.jpeg';
import harveyImg from '@/assets/imgs/voices/voice-Harvey.jpeg';
import hughImg from '@/assets/imgs/voices/voice-Hugh.jpeg';
import justinImg from '@/assets/imgs/voices/voice-Justin.jpeg';
import leslieImg from '@/assets/imgs/voices/voice-Leslie.jpeg';
import livImg from '@/assets/imgs/voices/voice-Liv.jpeg';
import millieImg from '@/assets/imgs/voices/voice-Millie.jpeg';
import monicaImg from '@/assets/imgs/voices/voice-Monica.jpeg';
import rupertImg from '@/assets/imgs/voices/voice-Rupert.jpeg';
import scarlettImg from '@/assets/imgs/voices/voice-Scarlett.jpeg';
import tylerImg from '@/assets/imgs/voices/voice-Tyler.jpeg';
import valerieImg from '@/assets/imgs/voices/voice-Valerie.jpeg';
import willImg from '@/assets/imgs/voices/voice-Will.jpeg';
import zacharyImg from '@/assets/imgs/voices/voice-Zachary.jpeg';
import type { VoiceName } from '@listening/shared';

export const voiceNameToAssetsMap: Record<
  VoiceName,
  { imgSrc: string; audioSrc: string }
> = {
  Will: {
    imgSrc: willImg.src,
    audioSrc: willAudio,
  },
  Scarlett: {
    imgSrc: scarlettImg.src,
    audioSrc: scarlettAudio,
  },
  Amy: {
    imgSrc: amyImg.src,
    audioSrc: amyAudio,
  },
  Dan: {
    imgSrc: danImg.src,
    audioSrc: danAudio,
  },
  Liv: {
    imgSrc: livImg.src,
    audioSrc: livAudio,
  },
  Elizabeth: {
    imgSrc: elizabethImg.src,
    audioSrc: elizabethAudio,
  },
  Millie: {
    imgSrc: millieImg.src,
    audioSrc: millieAudio,
  },
  Allison: {
    imgSrc: allisonImg.src,
    audioSrc: allisonAudio,
  },
  Valerie: {
    imgSrc: valerieImg.src,
    audioSrc: valerieAudio,
  },
  Leslie: {
    imgSrc: leslieImg.src,
    audioSrc: leslieAudio,
  },
  Beatrix: {
    imgSrc: beatrixImg.src,
    audioSrc: beatrixAudio,
  },
  Justin: {
    imgSrc: justinImg.src,
    audioSrc: justinAudio,
  },
  Zachary: {
    imgSrc: zacharyImg.src,
    audioSrc: zacharyAudio,
  },
  Tyler: {
    imgSrc: tylerImg.src,
    audioSrc: tylerAudio,
  },
  Hugh: {
    imgSrc: hughImg.src,
    audioSrc: hughAudio,
  },
  Rupert: {
    imgSrc: rupertImg.src,
    audioSrc: rupertAudio,
  },
  Monica: {
    imgSrc: monicaImg.src,
    audioSrc: monicaAudio,
  },
  Harvey: {
    imgSrc: harveyImg.src,
    audioSrc: harveyAudio,
  },
  Aaron: {
    imgSrc: aaronImg.src,
    audioSrc: aaronAudio,
  },
  Claire: {
    imgSrc: claireImg.src,
    audioSrc: claireAudio,
  },
};
