import IconArrowDown from '@/assets/icons/icon-arrow-down.svg?react';
import IconPauseWhite from '@/assets/icons/icon-pause-white.svg?react';
import IconPause from '@/assets/icons/icon-pause.svg?react';
import IconPlay from '@/assets/icons/icon-play.svg?react';
import Spinner from '@/components/misc/spinner';
import { safeApiClient } from '@/services/api-service';
import { voiceNameToAssetsMap } from '@/services/voice-service';
import {
  voicesMetadataMap,
  type components,
  type VoiceName,
} from '@listening/shared';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
} from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

const PLACEHOLDERS = [
  'Type something...',
  'Share your thoughts...',
  'Write your story...',
  'Express yourself...',
  'Let your words flow...',
];

const voiceNames = (Object.keys(voicesMetadataMap) as VoiceName[]).slice(0, 4);

function VoiceDropdown({
  selectedVoice,
  onVoiceSelect,
  isMainAudioPlaying,
  isProcessing,
  onVoiceSamplePlayingChange,
}: {
  selectedVoice: VoiceName | null;
  onVoiceSelect: (voice: VoiceName) => void;
  isMainAudioPlaying: boolean;
  isProcessing: boolean;
  className?: string;
  onVoiceSamplePlayingChange?: (isPlaying: boolean) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const audioPlayer = useGlobalAudioPlayer();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        if (playingVoice) {
          audioPlayer.pause();
          setPlayingVoice(null);
          onVoiceSamplePlayingChange?.(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [audioPlayer, onVoiceSamplePlayingChange, playingVoice]);

  // Stop voice sample playback when main audio starts playing
  useEffect(() => {
    if (isMainAudioPlaying && playingVoice) {
      audioPlayer.pause();
      setPlayingVoice(null);
      onVoiceSamplePlayingChange?.(false);
    }
  }, [
    isMainAudioPlaying,
    audioPlayer,
    playingVoice,
    onVoiceSamplePlayingChange,
  ]);

  const handleVoicePlayClick = (voice: string, e: React.MouseEvent) => {
    e.stopPropagation();

    if (playingVoice === voice) {
      audioPlayer.pause();
      setPlayingVoice(null);
      onVoiceSamplePlayingChange?.(false);
      return;
    }

    if (isMainAudioPlaying || isProcessing) {
      return; // Prevent playing new demo while main audio is active or processing
    }

    const samplePath = voiceNameToAssetsMap[voice as VoiceName].audioSrc;

    if (samplePath) {
      if (audioPlayer.playing) {
        audioPlayer.pause();
      }

      setPlayingVoice(voice);
      onVoiceSamplePlayingChange?.(true);

      audioPlayer.load(samplePath, {
        format: 'mp3',
        autoplay: true,
        html5: true,
        onend: () => {
          setPlayingVoice(null);
          onVoiceSamplePlayingChange?.(false);
        },
      });
    }
  };

  // Cleanup audio when dropdown closes
  useEffect(() => {
    if (!isOpen && playingVoice) {
      audioPlayer.pause();
      setPlayingVoice(null);
      onVoiceSamplePlayingChange?.(false);
    }
  }, [isOpen, audioPlayer, playingVoice, onVoiceSamplePlayingChange]);

  return (
    <div
      ref={dropdownRef}
      className="relative flex w-full justify-center sm:w-auto sm:justify-start"
    >
      <button
        type="button"
        disabled={isProcessing}
        className={`flex w-full items-center justify-center rounded-[45px] border-[1.5px] border-[rgba(190,190,190,0.2)] px-4 py-3 transition-colors duration-200 sm:justify-between ${
          isProcessing
            ? 'cursor-not-allowed opacity-50'
            : 'cursor-pointer hover:bg-gray-50'
        }`}
        onClick={() => {
          if (!isProcessing) {
            // If closing the dropdown, stop any playing voice samples
            if (isOpen && playingVoice) {
              audioPlayer.pause();
              setPlayingVoice(null);
              onVoiceSamplePlayingChange?.(false);
            }
            setIsOpen(!isOpen);
          }
        }}
      >
        <div className="flex items-center gap-2">
          <img
            src={
              selectedVoice ? voiceNameToAssetsMap[selectedVoice].imgSrc : ''
            }
            alt={selectedVoice ?? ''}
            className="size-8 rounded-full object-cover"
            width={32}
            height={32}
          />
          <span>{selectedVoice}</span>
        </div>
        <IconArrowDown
          className={`ml-2 size-7 transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute left-1/2 top-[calc(100%+0.5rem)] z-50 w-[300px] -translate-x-1/2 rounded-lg border border-[rgba(190,190,190,0.2)] bg-white p-2 shadow-lg sm:left-0 sm:translate-x-0">
          {voiceNames.map((voice) => {
            const voiceData = voicesMetadataMap[voice];
            const capitalizedSoftness =
              voiceData.softness.charAt(0).toUpperCase() +
              voiceData.softness.slice(1);
            const description = `${
              voiceData.gender === 'male' ? 'Male' : 'Female'
            } • ${capitalizedSoftness}`;
            return (
              <div
                key={voice}
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (!isProcessing) {
                    onVoiceSelect(voice);
                    setIsOpen(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    if (!isProcessing) {
                      onVoiceSelect(voice);
                      setIsOpen(false);
                    }
                  }
                }}
                className={`flex w-full items-center justify-between rounded-lg p-2 transition-colors ${
                  isProcessing
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer hover:bg-gray-50'
                }`}
              >
                <div className="flex w-full items-center justify-between px-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={voiceNameToAssetsMap[voice].imgSrc}
                      alt={voice}
                      className="size-12 rounded-full object-cover"
                      width={48}
                      height={48}
                    />
                    <div className="flex flex-col items-start">
                      <span className="text-base font-medium text-gray-900">
                        {voice}
                      </span>
                      <span className="text-sm text-gray-500">
                        {description}
                      </span>
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`flex size-8 items-center justify-center rounded-full bg-gray-100 transition-colors ${
                      playingVoice === voice
                        ? 'cursor-pointer hover:bg-gray-200'
                        : isMainAudioPlaying || isProcessing
                          ? 'cursor-not-allowed opacity-50'
                          : 'cursor-pointer hover:bg-gray-200'
                    }`}
                    onClick={(e) => {
                      handleVoicePlayClick(voice, e);
                    }}
                    disabled={
                      !playingVoice && (isMainAudioPlaying || isProcessing)
                    }
                  >
                    {playingVoice === voice ? (
                      <IconPause className="size-4" />
                    ) : (
                      <IconPlay className="size-4" />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function useTextAnimator() {
  const [currentPlaceholder, setCurrentPlaceholder] = useState('');
  const currentIndex = useRef(0);
  const charIndex = useRef(0);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    const typeText = () => {
      const placeholder = PLACEHOLDERS[currentIndex.current];
      if (!placeholder) return;

      if (charIndex.current < placeholder.length) {
        setCurrentPlaceholder(placeholder.slice(0, charIndex.current + 1));
        charIndex.current += 1;
        timeoutRef.current = window.setTimeout(typeText, 100);
      } else {
        timeoutRef.current = window.setTimeout(() => {
          timeoutRef.current = window.setTimeout(eraseText, 2000);
        }, 1000);
      }
    };

    const eraseText = () => {
      if (charIndex.current > 0) {
        charIndex.current -= 1;
        setCurrentPlaceholder((current) => current.slice(0, charIndex.current));
        timeoutRef.current = window.setTimeout(eraseText, 50);
      } else {
        currentIndex.current = (currentIndex.current + 1) % PLACEHOLDERS.length;
        timeoutRef.current = window.setTimeout(typeText, 500);
      }
    };

    // Start the animation
    timeoutRef.current = window.setTimeout(typeText, 100);

    return () => {
      if (timeoutRef.current !== undefined) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []); // Only run once on mount

  return currentPlaceholder;
}

const queryClient = new QueryClient();

export function TryListening({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <TryListeningInternal>{children}</TryListeningInternal>
    </QueryClientProvider>
  );
}

class DemoTtsError extends Error {}

class DemoTtsRateLimitError extends DemoTtsError {
  constructor() {
    super('Rate limit exceeded, please try again later');
  }
}

class DemoNoTextProvidedError extends DemoTtsError {
  constructor() {
    super('Please enter some text first');
  }
}

class DemoTtsProcessingError extends DemoTtsError {
  constructor() {
    super('Please wait while we process your current request');
  }
}

class DemoTextTooLongError extends DemoTtsError {
  constructor(maxLength: number) {
    super(
      `Text is too long. Maximum length is ${maxLength.toString()} characters.`,
    );
  }
}

class DemoApiError extends DemoTtsError {
  constructor(message = 'API request failed. Please try again.') {
    super(message);
  }
}

function TryListeningInternal({ children }: { children: React.ReactNode }) {
  const initialText =
    "Listening allows you to turn anything into audio, so you can listen to it on the go. Listen while at the gym, on a walk, or while cooking dinner!\n\nMany people find they can listen 2x, even 3x faster than they read. It's free to get started, so give Listening a try today!";
  const [text, setText] = useState(initialText);
  const [selectedVoice, setSelectedVoice] =
    useState<components['schemas']['Voice']>('Scarlett');
  const [showError, setShowError] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const errorTimeoutRef = useRef<number>();
  const MAX_LENGTH = 500;
  const [lastProcessedText, setLastProcessedText] = useState('');
  const [lastProcessedVoice, setLastProcessedVoice] =
    useState<components['schemas']['Voice']>('Scarlett');
  const [isVoiceSamplePlaying, setIsVoiceSamplePlaying] = useState(false);
  // Track the current audio source: 'main' or 'sample'
  const [currentAudioSource, setCurrentAudioSource] = useState<
    'main' | 'sample' | null
  >(null);

  const animatedPlaceholder = useTextAnimator();

  // Regular expression to match English letters, numbers, spaces, and basic punctuation
  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    // Regular expression to match English letters, numbers, spaces, and basic punctuation
    const validText = newText.replace(/[^a-zA-Z0-9\s.,!?'"()\n\r-]/g, '');
    setText(validText);
  };

  useEffect(() => {
    const timeout = errorTimeoutRef.current;
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, []);

  const audioPlayer = useGlobalAudioPlayer();
  const [isPlaying, setIsPlaying] = useState(false);

  // Update isPlaying state when audio player state changes
  useEffect(() => {
    if (!audioPlayer.playing) {
      // If audio stopped playing
      if (currentAudioSource === 'main') {
        // If it was main audio, update the playing state but keep the source
        setIsPlaying(false);
        // Only reset currentAudioSource if the audio ended naturally (position is at the end)
        // This allows us to resume from the same position when paused
        if (audioPlayer.getPosition() >= audioPlayer.duration - 0.1) {
          setCurrentAudioSource(null);
        }
      } else if (currentAudioSource === 'sample') {
        // If it was a voice sample, update the state
        setIsVoiceSamplePlaying(false);
        setCurrentAudioSource(null);
      }
    } else {
      // If audio started playing
      if (currentAudioSource === 'main') {
        // If it's main audio, update the state
        setIsPlaying(true);
      }
    }
  }, [
    audioPlayer.playing,
    audioPlayer.getPosition,
    audioPlayer.duration,
    audioPlayer,
    currentAudioSource,
    isVoiceSamplePlaying,
    setIsVoiceSamplePlaying,
  ]);

  const loadAndPlayMutation = useMutation({
    mutationFn: async (args: {
      text: string;
      voice: components['schemas']['Voice'];
    }) => {
      if (!args.text.trim()) throw new DemoNoTextProvidedError();

      if (args.text.length > MAX_LENGTH) {
        throw new DemoTextTooLongError(MAX_LENGTH);
      }

      if (audioPlayer.playing) {
        audioPlayer.pause();
      }

      if (isVoiceSamplePlaying) {
        setIsVoiceSamplePlaying(false);
      }

      setCurrentAudioSource('main');
      setIsPlaying(true);

      try {
        const result = await safeApiClient.POST('/v2/tts/demo-text', {
          body: {
            text: args.text,
            voice: args.voice,
          },
        });

        if (result.response.status === 429) throw new DemoTtsRateLimitError();
        if (result.response.status === 400) {
          if (result.response.statusText) {
            throw new DemoApiError(result.response.statusText);
          } else {
            throw new DemoApiError();
          }
        }
        if (!result.data)
          throw new DemoApiError(
            'No data returned from the server. Please try again.',
          );

        setLastProcessedText(args.text);
        setLastProcessedVoice(args.voice);

        const wavesurferEvent = new CustomEvent('stopAllWavesurfers');
        window.dispatchEvent(wavesurferEvent);

        audioPlayer.load(result.data.tts.url, {
          format: 'mp3',
          autoplay: true,
          html5: true,
          onload: () => {
            setIsPlaying(true);
            setCurrentAudioSource('main');
          },
          onend: () => {
            setIsPlaying(false);
            setCurrentAudioSource(null);
          },
        });
        return result.data.tts;
      } catch (error) {
        if (!(error instanceof DemoTtsError)) {
          if (error instanceof Error) {
            throw new DemoApiError(error.message);
          } else {
            throw new DemoApiError();
          }
        }
        throw error;
      }
    },
    onError: () => {
      setIsPlaying(false);
      setCurrentAudioSource(null);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    },
  });

  const { mutate } = loadAndPlayMutation;
  const isLoading = loadAndPlayMutation.isPending || audioPlayer.isLoading;

  // Reset audio when text changes
  const audioPlayerRef = useRef(audioPlayer);

  useEffect(() => {
    if (audioPlayerRef.current.isReady) {
      audioPlayerRef.current.pause();
      setIsPlaying(false);
      setCurrentAudioSource(null);
    }
  }, [text, selectedVoice, currentAudioSource]);

  const playHandler = useCallback(
    (voice?: components['schemas']['Voice']) => {
      const playVoice = voice ?? selectedVoice;

      if (isLoading) {
        throw new DemoTtsProcessingError();
      }

      if (isVoiceSamplePlaying || currentAudioSource === 'sample') {
        audioPlayer.pause();
        setIsVoiceSamplePlaying(false);

        const hasContentChanged =
          text !== lastProcessedText || playVoice !== lastProcessedVoice;

        if (!hasContentChanged && lastProcessedText && audioPlayer.isReady) {
          setCurrentAudioSource('main');
          setIsPlaying(true);
          audioPlayer.play();
          return;
        } else {
          setCurrentAudioSource(null);
        }
      }

      if (audioPlayer.playing && (currentAudioSource === 'main' || isPlaying)) {
        audioPlayer.pause();
        setIsPlaying(false);
        return;
      }

      const hasContentChanged =
        text !== lastProcessedText || playVoice !== lastProcessedVoice;

      if (hasContentChanged || currentAudioSource !== 'main') {
        mutate({
          text,
          voice: playVoice,
        });
        return;
      }

      audioPlayer.play();
      setIsPlaying(true);
      setCurrentAudioSource('main');
      return;
    },
    [
      mutate,
      selectedVoice,
      text,
      isLoading,
      isPlaying,
      audioPlayer,
      lastProcessedText,
      lastProcessedVoice,
      isVoiceSamplePlaying,
      setIsVoiceSamplePlaying,
      currentAudioSource,
    ],
  );

  return (
    <section
      className="z-50 pt-6 text-center sm:pt-16 md:pt-24"
      id="try-listening-section"
      aria-labelledby="try-listening-heading"
    >
      <h2
        id="try-listening-heading"
        className="mb-4 text-4xl font-bold sm:mb-6 sm:text-5xl md:text-6xl lg:text-7xl"
      >
        Try the magic for yourself
      </h2>
      <p className="mx-auto mb-8 max-w-4xl px-4 text-sm text-[#6F6F6F] sm:mb-10 sm:text-lg md:mb-12">
        &quot;I couldn&apos;t believe this is A.I. generated!&quot; -- Does it
        sound like a real person? Give it a try!
      </p>

      <div className="flex flex-col items-center gap-4 px-4">
        <div className="relative h-[200px] w-full rounded-[24px] border-[12px] border-[#E4EDF3] bg-white shadow-lg sm:h-[225px] sm:w-4/5 md:h-[250px] md:w-2/3 lg:h-[275px] lg:w-1/2">
          <textarea
            value={text}
            onChange={handleTextChange}
            onFocus={() => {
              setIsInputFocused(true);
            }}
            onBlur={() => {
              setIsInputFocused(false);
            }}
            placeholder={isInputFocused ? '' : animatedPlaceholder}
            maxLength={MAX_LENGTH}
            className="size-full resize-none rounded-lg p-4 text-sm text-[#6F6F6F] outline-none sm:text-base"
          />
        </div>

        <div className="mt-2 flex w-full flex-col items-center justify-between gap-4 sm:w-4/5 sm:flex-row sm:gap-2 md:w-2/3 lg:w-1/2">
          <VoiceDropdown
            selectedVoice={selectedVoice}
            onVoiceSelect={(voiceItem) => {
              const voice = voiceItem as components['schemas']['Voice'];
              setSelectedVoice(voice);
            }}
            isMainAudioPlaying={isPlaying}
            isProcessing={isLoading}
            onVoiceSamplePlayingChange={(isPlaying) => {
              setIsVoiceSamplePlaying(isPlaying);
              if (isPlaying) {
                // When starting to play a voice sample, remember that we were playing main audio
                // but don't reset the currentAudioSource if we're stopping a voice sample
                const wasPlayingMainAudio = currentAudioSource === 'main';
                setCurrentAudioSource('sample');

                // Store the fact that we were playing main audio before
                if (wasPlayingMainAudio) {
                  console.log('Remembering that main audio was playing');
                  // We could store this in a ref or state if needed
                }
              } else if (currentAudioSource === 'sample') {
                // When stopping a voice sample, don't automatically reset the audio source
                // This will be handled by the playHandler based on whether we should resume main audio
                console.log('Voice sample stopped, maintaining audio state');
              }
            }}
            className="w-full sm:w-auto"
          />

          <div className="flex w-full items-center gap-4 sm:w-auto">
            <span
              id="character-count"
              className="text-sm text-gray-500"
              aria-live="polite"
            >
              {text.length} / {MAX_LENGTH} characters
            </span>

            <div className="relative w-full sm:w-auto">
              <button
                type="button"
                onClick={() => {
                  playHandler();
                }}
                disabled={isLoading}
                className="flex w-full items-center justify-center gap-2 rounded-full bg-[#102087] px-6 py-3 text-base text-white transition-colors duration-200 hover:bg-gray-900 disabled:cursor-not-allowed disabled:opacity-70 sm:w-auto sm:px-8 sm:text-lg"
                aria-label={isPlaying ? 'Pause playback' : 'Start playback'}
              >
                {isLoading ? (
                  <Spinner />
                ) : isPlaying ? (
                  <IconPauseWhite className="size-3" aria-hidden="true" />
                ) : (
                  <IconPlay className="size-4" aria-hidden="true" />
                )}
                <span>
                  {isLoading ? 'Loading...' : isPlaying ? 'Playing' : 'Play'}
                </span>
              </button>

              {showError && loadAndPlayMutation.error && (
                <div
                  className="absolute left-1/2 top-full mt-2 w-max max-w-[calc(100vw-2rem)] -translate-x-1/2 rounded-lg bg-red-50 px-3 py-2 text-sm font-medium text-red-600 shadow-sm ring-1 ring-red-500/10 sm:left-0 sm:max-w-none sm:translate-x-0"
                  role="alert"
                  aria-live="assertive"
                >
                  <div className="flex items-center gap-1.5">
                    <ErrorIcon className="size-4" aria-hidden="true" />
                    {loadAndPlayMutation.error instanceof DemoTtsError
                      ? loadAndPlayMutation.error.message
                      : 'An unexpected error occurred. Please try again.'}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {children}
    </section>
  );
}

// Icon components
function ErrorIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill="currentColor"
        d="M12 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0-8.75a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 22c-5.523 0-10-4.477-10-10S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-1.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z"
      />
    </svg>
  );
}
